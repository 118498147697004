import { SourceSansPro } from './fonts'
import { globalCss } from '@/styles/stitches.config'

export const globalStyles = globalCss({
  '*': {
    '-webkit-print-color-adjust': 'exact',
    printColorAdjust: 'exact',
  },
  body: {
    ...SourceSansPro.style,
    fontSize: '$3',
    color: '$slate12',
    margin: '0 auto',
    position: 'relative',
  },
})
